import './sass/Privacy.scss';

const Privacy = function () {
  return (
    <section>
      <div className="privacy-header">
        <h2>Privacy Policy</h2>
        <p>Talk legal to me</p>
      </div>

      <div className="privacy-content">
        <div>
          <h3>Chrone Privacy Policy</h3>
          <p>Last updated: November 5, 2024</p>
          <p>
            This Privacy Policy describes how we collect, receive, use, store,
            share, transfer, and process your Personal Data. It also describes
            your choices and rights with respect to your Personal Data,
            including your rights of access and correction of your Personal
            Data. For the purposes of this Privacy Policy "Personal Data" means
            any information relating to an identified or identifiable
            individual. If you do not agree with this Privacy Policy, you should
            not use our websites, product and services, or any other sites or
            services that link to this Privacy Policy.
          </p>
        </div>

        <div>
          <h3>Interpretation and Definitions</h3>

          <p>
            a. Interpretation <br />
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>

          <p>
            b. Definitions <br />
            For the purposes of this Privacy Policy:
            <br />
            <span>Account</span> means a unique account created for You to
            access our Service or parts of our Service.
            <br />
            <span>Affiliate</span> means an entity that controls, is controlled
            by or is under common control with a party, where "control" means
            ownership of 50% or more of the shares, equity interest or other
            securities entitled to vote for election of directors or other
            managing authority.
            <br />
            <span>Company</span> (referred to as either "the Company", "We",
            "Us" or "Our" in this Agreement) refers to Chrone Influitive
            Company, Yaba.
            <br />
            <span>Cookies</span> are small files that are placed on Your
            computer, mobile device or any other device by a website, containing
            the details of Your browsing history on that website among its many
            uses.
            <br />
            <span>Country</span> refers to: Nigeria.
            <br />
            <span>Device</span> means any device that can access the Service
            such as a computer, a cellphone or a digital tablet.
            <br />
            <span>Personal Data</span> is any information that relates to an
            identified or identifiable individual. Service refers to the
            Website.
            <br />
            <span>Service Provider</span> means any natural or legal person who
            processes the data on behalf of the Company. It refers to
            third-party companies or individuals employed by the Company to
            facilitate the Service, to provide the Service on behalf of the
            Company, to perform services related to the Service or to assist the
            Company in analyzing how the Service is used.
            <br />
            <span>Third-party Social Media Service</span> refers to any website
            or any social network website through which a User can log in or
            create an account to use the Service.
            <br />
            <span>Usage Data</span> refers to data collected automatically,
            either generated by the use of the Service or from the Service
            infrastructure itself (for example, the duration of a page visit).
            <br />
            <span>Website</span> refers to Chrone Influitive Company, accessible
            from chronecompany.org
            <br />
            <span>You</span> means the individual accessing or using the
            Service, or the company, or other legal entity on behalf of which
            such individual is accessing or using the Service, as applicable.
            <br />
          </p>
        </div>

        <div>
          <h3>Collecting and Using Your Personal Data</h3>

          <p>
            <span>Types of Data Collected</span>
          </p>

          <p>
            <span>Personal Data</span> <br />
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to: <br />
            Email address
            <br />
            First name and last name
            <br />
            Phone number
            <br />
            Usage Data
          </p>

          <p>
            <span>Usage Data</span> <br />
            Usage Data is collected automatically when using the Service.
            <br />
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
            <br />
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to, the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers and other diagnostic data.
            <br />
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
            <br />
          </p>

          <p>
            <span>Information from Third-Party Social Media Services</span>
            <br />
            The Company allows You to create an account and log in to use the
            Service through the following Third-party Social Media Services:{' '}
            <br />
            · Google
            <br />
            · Facebook
            <br />
            · Twitter
            <br />
            · LinkedIn
            <br />
            If You decide to register through or otherwise grant us access to a
            Third-Party Social Media Service, We may collect Personal data that
            is already associated with Your Third-Party Social Media Service's
            account, such as Your name, Your email address, Your activities or
            Your contact list associated with that account.
            <br />
            You may also have the option of sharing additional information with
            the Company through Your Third-Party Social Media Service's account.
            If You choose to provide such information and Personal Data, during
            registration or otherwise, You are giving the Company permission to
            use, share, and store it in a manner consistent with this Privacy
            Policy.
          </p>

          <p>
            <span>Tracking Technologies and Cookies</span>
            <br />
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include: <br />·{' '}
            <span>Cookies or Browser Cookies.</span> A cookie is a small file
            placed on Your Device. You can instruct Your browser to refuse all
            Cookies or to indicate when a Cookie is being sent. However, if You
            do not accept Cookies, You may not be able to use some parts of our
            Service. Unless you have adjusted Your browser setting so that it
            will refuse Cookies, our Service may use Cookies.
            <br />· <span>Web Beacons.</span> Certain sections of our Service
            and our emails may contain small electronic files known as web
            beacons (also referred to as clear gifs, pixel tags, and
            single-pixel gifs) that permit the Company, for example, to count
            users who have visited those pages or opened an email and for other
            related website statistics (for example, recording the popularity of
            a certain section and verifying system and server integrity).
            <br />
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. Learn more about cookies on the Free Privacy Policy
            website article.
            <br />
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>

          <p>
            <span>Necessary / Essential Cookies</span>
            <br />
            Type: Session Cookies
            <br />
            Administered by: Us
            <br />
            Purpose: These Cookies are essential to provide You with services
            available through the Website and to enable You to use some of its
            features. They help to authenticate users and prevent fraudulent use
            of user accounts. Without these Cookies, the services that You have
            asked for cannot be provided, and We only use these Cookies to
            provide You with those services.
            <br />
            Cookies Policy / Notice Acceptance Cookies
            <br />
            Type: Persistent Cookies
            <br />
            Administered by: Us
            <br />
            Purpose: These Cookies identify if users have accepted the use of
            cookies on the Website.
            <br />
            Functionality Cookies
            <br />
            Type: Persistent Cookies
            <br />
            Administered by: Us
            <br />
            Purpose: These Cookies allow us to remember choices You make when
            You use the Website, such as remembering your login details or
            language preference. The purpose of these Cookies is to provide You
            with a more personal experience and to avoid You having to re-enter
            your preferences every time You use the Website.
            <br />
            For more information about the cookies we use and your choices
            regarding cookies, please visit our Cookies Policy or the Cookies
            section of our Privacy Policy.
          </p>

          <p>
            <span> Use of Your Personal Data</span>
            <br />
            The Company may use Personal Data for the following purposes:
            <br />
            <span>To provide and maintain our Service,</span> including to
            monitor the usage of our Service.
            <br />
            <span>To manage Your Account</span>: to manage Your registration as
            a user of the Service. The Personal Data You provide can give You
            access to different functionalities of the Service that are
            available to You as a registered user.
            <br />
            For the performance of a contract: the development, compliance and
            undertaking of the purchase contract for the products, items or
            services You have purchased or of any other contract with Us through
            the Service.
          </p>

          <p>
            <span>To contact You:</span> To contact You by email, telephone
            calls, SMS, or other equivalent forms of electronic communication,
            such as a mobile application's push notifications regarding updates
            or informative communications related to the functionalities,
            products or contracted services, including the security updates,
            when necessary or reasonable for their implementation.
          </p>

          <p>
            <span>To provide You</span> with news, special offers and general
            information about other goods, services and events which we offer
            that are similar to those that you have already purchased or
            enquired about unless You have opted not to receive such
            information. <br />
            To manage Your requests: To attend and manage Your requests to Us.
          </p>

          <p>
            <span>For business transfers:</span> We may use Your information to
            evaluate or conduct a merger, divestiture, restructuring,
            reorganization, dissolution, or other sale or transfer of some or
            all of Our assets, whether as a going concern or as part of
            bankruptcy, liquidation, or similar proceeding, in which Personal
            Data held by Us about our Service users is among the assets
            transferred. <br />
            For other purposes: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and to evaluate and
            improve our Service, products, services, marketing and your
            experience.
          </p>

          <p>
            We may share Your personal information in the following situations:{' '}
            <br />· <span>With Service Providers</span>: We may share Your
            personal information with Service Providers to monitor and analyze
            the use of our Service, to contact You.
            <br />
            · For business transfers: We may share or transfer Your personal
            information in connection with, or during negotiations of, any
            merger, sale of Company assets, financing, or acquisition of all or
            a portion of Our business to another company.
            <br />· <span>With Affiliates</span>: We may share Your information
            with Our affiliates, in which case we will require those affiliates
            to honor this Privacy Policy. Affiliates include Our parent company
            and any other subsidiaries, joint venture partners or other
            companies that We control or that are under common control with Us.
            <br />· <span>With business partners</span>: We may share Your
            information with Our business partners to offer You certain
            products, services or promotions.
            <br />· <span>With other users</span>: when You share personal
            information or otherwise interact in the public areas with other
            users, such information may be viewed by all users and may be
            publicly distributed outside. If You interact with other users or
            register through a Third-Party Social Media Service, Your contacts
            on the Third-Party Social Media Service may see Your name, profile,
            pictures and description of Your activity. Similarly, other users
            will be able to view descriptions of Your activity, communicate with
            You and view Your profile.
            <br />· <span>With Your consent</span>: We may disclose Your
            personal information for any other purpose with Your consent.
          </p>

          <p>
            <span>Retention of Your Personal Data</span> <br />
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies. The Company will also
            retain Usage Data for internal analysis purposes. Usage Data is
            generally retained for a shorter period of time, except when this
            data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain
            this data for longer time periods.
          </p>

          <p>
            <span>Transfer of Your Personal Data</span> <br />
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction. <br />
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
            <br />
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>

          <p>
            <span>Delete Your Personal Data</span> <br />
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You. Our Service may
            give You the ability to delete certain information about You from
            within the Service.
            <br />
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
            <br />
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>

          <p>
            <span>Disclosure of Your Personal Data</span>
          </p>

          <p>
            <span>Google API Service Usage</span>
            <br />
            Chrone Influitive Company's use and transfer to any other app of
            information received from Google APIs will adhere to{' '}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
            >
              Google API Services User Data Policy
            </a>
            , including the Limited Use requirements.
          </p>

          <p>
            <span>Business Transactions</span>
            <br />
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>

          <p>
            <span>Law enforcement</span>
            <br />
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>

          <p>
            <span>Other legal requirements</span> <br />
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to: <br />
            · Comply with a legal obligation <br />
            · Protect and defend the rights or property of the Company <br />
            · Prevent or investigate possible wrongdoing in connection with the
            Service
            <br />
            · Protect the personal safety of Users of the Service or the public
            <br />· Protect against legal liability
          </p>

          <p>
            <span>Security of Your Personal Data</span>
            <br />
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
        </div>

        <div>
          <h3>Children's Privacy</h3>

          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
            <br />
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
        </div>

        <div>
          <h3>Links to Other Websites</h3>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
            <br />
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
            <br />
            <span>Changes to this Privacy Policy</span>
            <br />
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
            <br />
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
            <br />
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>

        <div>
          <h3>Other Important Privacy Information</h3>

          <p>
            <span>We Never Sell Personal Data </span>
            <br />
            We will never sell your Personal Data to any third party.
          </p>

          <p>
            <span>Information About Children</span>
            <br />
            The websites, product and services are not intended for or targeted
            at children under 16, and we do not knowingly or intentionally
            collect Personal Data about children under 16. If you believe that
            we have collected Personal Data about a child under 16, please
            contact us here, so that we may delete the information.
          </p>
        </div>

        <div>
          <h3>Contact Us</h3>

          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
            <br />
            By email: admin@chronecompany.org
            <br />
            By visiting this page on our website:{' '}
            <a href="https://www.chronecompany.org/strategy">
              chronecompany.org
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Privacy;
