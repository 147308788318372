import Footer from '../components/Footer/Footer';
import Privacy from '../components/PrivacyPageContent/Privacy';

const PrivacyPage = function () {
  return (
    <>
      <Privacy />

      <Footer />
    </>
  );
};

export default PrivacyPage;
