import AboutUs from '../components/AboutPageContent/AboutUs';
import CopyRight from '../components/Footer/CopyRight';

const AboutPage = function () {
  return (
    <>
      <AboutUs />

      <CopyRight />
    </>
  );
};

export default AboutPage;
