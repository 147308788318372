import './copyright.scss';

const CopyRight = function () {
  return (
    <section className="copyright">
      <p>Copyright@ 2023 Chrone Company</p>
      <p>Privacy policy</p>
    </section>
  );
};

export default CopyRight;
